import { Component } from "react";
import { connect } from "react-redux";
import Tooltip from "@components/global/Tooltip";
import InfluencerProfileModal from "@components/BrandInfluencerDiscover/Profile/InfluencerProfileModal";
import avatar from "@assets/avatar.webp";
import InfluencerListModal from "../../InfluencerListModal";
import SocialListIcons from "../../../constants/SocialListIcons";
import "./styles.css";
import Emitter from "../../../constants/Emitter";
import moment from "moment";
import { RiExternalLinkLine } from "react-icons/ri";
import { FiEye, FiUsers } from "react-icons/fi";
import { FaRegHeart } from "react-icons/fa";

const FormatedNumber = ({ num }) => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "B";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
};

class InstagramItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileModal: false,
      showmodal: false,
      influencerUserName: "",
    };
  }
  componentDidMount() {}

  handleInfluencerProfileModal = async (id) => {
    const { handleSearchQuery } = this.props;
    if (id) {
      handleSearchQuery({ q: "", user_id: "", isViewButton: false });
      await this.props.viewInfluencerProfile(id);
      this.setState({
        profileModal: true,
      });
    }
  };

  handleClose = () => {
    this.setState({
      profileModal: false,
    });
  };

  _handleInfluencerSelection = (e, influencer) => {
    const { addInfluencer } = this.props;
    addInfluencer(e.target.checked, influencer);
  };

  showInfluencerList = (userName) => {
    if (this.props.refreshData.is_admin) {
      this.setState({ showmodal: true });
      this.setState({ influencerUserName: userName });
      let query = {
        platform: this.props.platform,
      };
      this.props.fetchBrandLists(query);
    } else {
      Emitter.emit("PERMISSION_POPUP");
    }
  };

  handleListModal = (influencer)=>{
    const { addInfluencer,selected_influencers } = this.props;
    const existInfluencer = selected_influencers.some((selectedInfluencer) => selectedInfluencer.iq_user_id  === influencer.iq_user_id);
    if(!existInfluencer){
      addInfluencer(true, influencer);
      setTimeout(() => {
        this.showInfluencerList();
      }, 100);
    }else{
      setTimeout(() => {
        this.showInfluencerList();
      }, 100);
    }
  }

  closeInfluenceModalList = () => {
    this.setState({ showmodal: false });
  };

  searchBrand = (data) => {
    this.props.fetech.searchBrand(data);
  };

  handleImageError = (event) => {
    const { currentTarget } = event;
    this.setState({ errorCount: (prevErrorCount) => prevErrorCount + 1 });

    if (this.state.errorCount < 3) {
      const fallbackImage =
        this.props.influencer.user_profile?.picture ||
        `${process.env.REACT_APP_BASE_URL}/images/male_avatar.webp`;
      currentTarget.src = fallbackImage;
    } else {
      currentTarget.src = avatar;
    }
  };

  render() {
    const { isProfileLoading, influencer, platform, selected_influencers } =
      this.props;

    return (
      <>
        <div
          className={
            "relative outline outline-1 hover:opacity-80 highlight_parent gap-5 flex-wrap flex items-center py-5 lg:px-10 px-5 bg-bgBodyLight rounded-[8px] overflow-hidden mb-6 sm:!mb-0 group" +
            (selected_influencers.find(
              (selectedInfluencer) =>
                selectedInfluencer.iq_user_id  ===
                influencer.iq_user_id
            )
              ? "shadow-[0_0_10px_0_#603ed3] outline-primary selected-user "
              : "shadow-[0px_4px_5px_#96969640] outline-transparent")
          }
        >
          <div className="flex shrink-0 w-full max-w-xl items-center gap-x-5">
            <label
              className="cursor-pointer flex items-center text-[15px] font-normal"
            >
              <input
                type="checkbox"
                disabled={selected_influencers.some(
                  (selectedInfluencer) =>
                    selectedInfluencer.iq_user_id === influencer.iq_user_id &&
                    selectedInfluencer.isActive
                )}
                checked={
                  selected_influencers && selected_influencers.length > 0
                    ? selected_influencers.some((selectedInfluencer) =>
                        selectedInfluencer.iq_user_id !==
                        influencer.iq_user_id
                          ? false
                          : true
                      )
                    : false
                }
                onChange={(e) =>
                  this._handleInfluencerSelection(e, influencer)
                }
                className="hidden peer"
              />
              <span className="peer-checked:bg-[#fff] bg-transparent h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[4.2px] before:left-[1.1px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-bgBodyLight inline-block border-2 border-[#fff] rounded-sm"></span>
            </label>
            <div
              className="relative cursor-pointer"
              onClick={() =>
                this.handleInfluencerProfileModal(influencer.iq_user_id)
              }
            >
              <img
                 src={influencer?.profile_picture_url}
                 alt={influencer.infl_name}
                className="min-h-[100px] max-h-[100px] rounded-full w-full"
                onError={this.handleImageError}
              />

            </div>
            <div className="space-y-2">
              <b className="block text-white">
                <Tooltip
                  trigger={
                    <p className="card-name font-semibold text-xl">
                      {" "}
                      {influencer?.infl_name
                        ? influencer?.infl_name
                        : ""}
                    </p>
                  }
                  tooltipText={
                    influencer?.infl_name
                      ? influencer?.infl_name
                      : ""
                  }
                  placement="top-left"
                />
              </b>
              <p className="text-white">{influencer?.infl_name}</p>
            </div>
          </div>
          <div className="flex grow justify-between gap-x-10">
            <div className="flex items-center gap-x-5">
              <FiUsers size={20} color="white" />
              <div className="space-y-1">
                <p className="text-white font-normal opacity-60">
                  Followers
                </p>
                <b className="text-white">
                  <FormatedNumber num={influencer?.followers_count} />
                </b>
              </div>
            </div>
            <div className="flex items-center gap-x-5">
              <FaRegHeart size={20} color="white" />
              <div className="space-y-1">
                <p className="text-white font-normal opacity-60">
                  Engagement Rate
                </p>
                <b className="text-white">
                  {influencer.engagement_rate
                    ? (influencer.engagement_rate * 100).toFixed(2)
                    : 0}
                  % 
                </b>
              </div>
            </div>
            {
              platform === "instagram" && 
              <div className="flex items-center gap-x-5">
                <FaRegHeart size={20} color="white" />
                <div className="space-y-1">
                  <p className="text-white font-normal opacity-60">
                    Engagement Count
                  </p>
                  <b className="text-white">
                    {influencer.engagements ? (
                      <FormatedNumber num={influencer.engagements} />
                    ) : (
                      0
                    )}{" "}
                  </b>
                </div>
              </div>
            }
            {platform !== "instagram" && (
              <div className="flex items-center gap-x-5">
                <FiEye size={20} color="white" />
                <div className="space-y-1">
                  <p className="text-white font-normal opacity-60">
                    Views Count
                  </p>
                  <b className="text-white">
                    {influencer &&
                      influencer.avg_views ? (
                      <FormatedNumber num={influencer.avg_views} />
                    ) : (
                      0
                    )}
                  </b>
                </div>
              </div>
            )}
            <button onClick={()=>this.handleListModal(influencer)} className="text-white highlight_bg hover:drop-shadow-[0_5px_20px_rgba(255,255,255,1)] bg-bgBodyDark py-2 px-10">Add to list</button>
          </div>
        </div>
        <InfluencerProfileModal
          isProfileLoading={isProfileLoading}
          platform={platform}
          open={this.state.profileModal}
          onClose={() => this.handleClose()}
        />
        <InfluencerListModal
          show={this.state.showmodal}
          platform={platform}
          closeModal={this.closeInfluenceModalList}
          userId={this.state.influencerUserName}
          brandLists={this.props.brandLists}
          searchBrand={this.props.searchBrand}
          createBrand={this.props.addNewBrand}
          addInfluencer={this.props.addInfluencerToList}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isProfileLoading: state.influencerSearch.isProfileLoading,
    platform: state.influencerAnalyzer.platform,
    selected_influencers: state.campaign.selected_analyzer_influencers,
    brandLists: state.brandList.brandlists,
    refreshData: state.HeaderReducer.refreshData,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("@store/redux/InfluencerSearchRedux");
  const {
    actions: analyzeractions,
  } = require("@store/redux/InfluencerAnalyzerRedux");
  const { types } = require("@store/redux/CampaignRedux");
  const { actions: brandlistactions } = require("@store/redux/BrandListRedux");
  return {
    ...ownProps,
    ...stateProps,
    viewInfluencerProfile: (data) => {
      actions.viewInfluencerProfile(dispatch, data);
    },
    handleSearchQuery: (data) => {
      analyzeractions.handleSearchQuery(dispatch, data);
    },
    addInfluencer: (status, influencer) => {
      dispatch({
        type: types.HANDLE_ADD_INFLUENCER,
        data: {
          status: status,
          influencer: influencer,
          flag: true,
          type: "analyzer",
        },
      });
    },

    fetchBrandLists: (data) => {
      brandlistactions.fetchBrandLists(dispatch, data);
    },

    searchBrand: (data) => {
      brandlistactions.searchBrand(dispatch, data);
    },
    addNewBrand: (data) => {
      brandlistactions.addNewBrand(dispatch, data);
    },

    addInfluencerToList: (data) => {
      brandlistactions.addInfluencerToList(dispatch, data);
    },
  };
};

export default connect(mapStateToProps, undefined, mergeProps)(InstagramItems);
