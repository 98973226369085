import { Component } from "react";
import { connect } from "react-redux";
import LinkTo from "./global/LinkTo";
import { Disclosure, Transition } from "@headlessui/react";
import { NavLink } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";

class SettingTabSidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0,
        };
    }

    componentDidMount = () => {
        const pathname = window.location.pathname;
        if (pathname === "/account" || pathname === "/account/close-account") {
            this.setState({
                activeTab: 1,
            });
        }
        if (
            pathname === "/permission" ||
            pathname === "/permission/invitation"
        ) {
            this.setState({
                activeTab: 3,
            });
        }
        if (
            pathname === "/billing" ||
            pathname === "/billing/payment-method" ||
            pathname === "/billing/invoice" ||
            pathname === "/billing/credit"
        ) {
            this.setState({
                activeTab: 4,
            });
        }
        if (
            pathname === "/integration" ||
            pathname === "/integration/smtp" ||
            pathname === "/integration/shopify" ||
            pathname === "/integration/analytics" ||
            pathname === "/integration/credentials"
        ) {
            this.setState({
                activeTab: 5,
            });
        }
        if (
            pathname === "/history/account-history" ||
            pathname === "/history/credits-history"
        ) {
            this.setState({
                activeTab: 6,
            });
        }
    };
    render() {
        const { refreshData, hasPlan } = this.props;
        const pathname = window.location.pathname;
		const defaultConfOpen = pathname === "/reseller/installation" || pathname === "/reseller/product-pricing" || pathname === "/reseller/custom-smtp" || pathname === "/reseller/stripe"
		const defaultOpen = pathname === "/reseller/revenue" || pathname === "/reseller/customer" || pathname === "/reseller/discount" || pathname === "/reseller/collected-feedback" || pathname === "/reseller/invoices"
        return (
            <div>
                <div className="bg-bgBodyLight shadow-[0px_4px_5px_#96969640] rounded-[8px] overflow-hidden">
                    <div className="flex flex-col" role="tablist">
                        <LinkTo
                            to="/account"
                            className={`text-white border-l-[3px] border-b border-b-[#e6e6e7] py-[8px] px-[1.25rem] text-[15px] flex justify-between focus-visible:outline-[0px] ${
                                this.state.activeTab === 1
                                    ? "font-semibold border-l-[#343749] bg-primary"
                                    : "font-normal border-l-transparent"
                            }`}
                            text="Account"
                        />
                    </div>
                </div>
                {refreshData.is_main && (
                    <div className="bg-bgBodyLight shadow-[0px_4px_5px_#96969640] rounded-[8px] overflow-hidden mt-6">
                        <div className="flex flex-col" role="tablist">
                            <LinkTo
                                to="/permission"
                                className={`text-white border-l-[3px] border-b border-b-[#e6e6e7] py-[8px] px-[1.25rem] text-[15px] flex justify-between focus-visible:outline-[0px] ${
                                    this.state.activeTab === 3
                                        ? "font-semibold border-l-[#343749] bg-primary"
                                        : "font-normal border-l-transparent"
                                }`}
                                text="Workspaces"
                            />
                        </div>
                    </div>
                )}
                {refreshData.is_main && this.props.hasPlan && (
                    <div className="bg-bgBodyLight shadow-[0px_4px_5px_#96969640] rounded-[8px] overflow-hidden mt-6">
                        <div className="flex flex-col" role="tablist">
                            <LinkTo
                                to="/billing"
                                className={`text-white border-l-[3px] border-b border-b-[#e6e6e7] py-[8px] px-[1.25rem] text-[15px] flex justify-between focus-visible:outline-[0px] ${
                                    this.state.activeTab === 4
                                        ? "font-semibold border-l-[#343749] bg-primary"
                                        : "font-normal border-l-transparent"
                                }`}
                                text="Billing"
                            />
                        </div>
                    </div>
                )}
                <div className="bg-bgBodyLight shadow-[0px_4px_5px_#96969640] rounded-[8px] overflow-hidden mt-6">
                    <div className="flex flex-col" role="tablist">
                        <LinkTo
                            to="/integration"
                            className={`text-white border-l-[3px] border-b border-b-[#e6e6e7] py-[8px] px-[1.25rem] text-[15px] flex justify-between focus-visible:outline-[0px] ${
                                this.state.activeTab === 5
                                    ? "font-semibold border-l-[#343749] bg-primary"
                                    : "font-normal border-l-transparent"
                            }`}
                            text="Integrations"
                        />
                    </div>
                </div>
                <div className="bg-bgBodyLight shadow-[0px_4px_5px_#96969640] rounded-[8px] overflow-hidden mt-6">
                    <div className="flex flex-col" role="tablist">
                        <LinkTo
                            to="/history/credits-history"
                            className={`text-white border-l-[3px] border-b border-b-[#e6e6e7] py-[8px] px-[1.25rem] text-[15px] flex justify-between focus-visible:outline-[0px] ${
                                this.state.activeTab === 6
                                    ? "font-semibold border-l-[#343749] bg-primary"
                                    : "font-normal border-l-transparent"
                            }`}
                            text="Credits Usage"
                        />
                    </div>
                </div>
                {this.props.appUrl?.includes("app.btrendy.co") && this.props?.showReseller  &&
                    <div className="bg-bgBodyLight shadow-[0px_4px_5px_#96969640] rounded-[8px] overflow-hidden mt-6">
                        <div className="flex flex-col">
                            <Disclosure defaultOpen={defaultConfOpen || defaultOpen}>
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button className="flex w-full justify-between items-center gap-3 text-white border-b border-b-[#e6e6e7] py-[8px] px-[1.25rem] text-[15px] text-left font-medium focus:outline-none focus-visible:ring focus-visible:ring-primary/75">
                                            <span className="font-normal shrink-0 text-[15px]">
                                                Reseller
                                            </span>
                                            <IoIosArrowDown
                                                className={`${
                                                    open
                                                        ? "rotate-180 transform"
                                                        : ""
                                                } h-5 w-5 text-white`}
                                            />
                                        </Disclosure.Button>
                                        <Transition
                                            show={open}
                                            enter="transition duration-100 ease-out"
                                            enterFrom="transform scale-95 opacity-0"
                                            enterTo="transform scale-100 opacity-100"
                                            leave="transition duration-75 ease-out"
                                            leaveFrom="transform scale-100 opacity-100"
                                            leaveTo="transform scale-95 opacity-0"
                                        >
                                            <Disclosure.Panel className="px-4 flex flex-col gap-y-2 pb-2 pt-4 items-start">
                                                <LinkTo
                                                    to="/reseller/revenue"
                                                    className={`${defaultOpen ? 'bg-primary' : ''} mb-1 hidden items-start text-white w-full px-2 py-1 rounded-lg gap-3 text-[14px] font-normal`}
                                                    text="Operation"
                                                />
                                                <NavLink
                                                    to="/reseller/installation"
                                                    className={
                                                        `${defaultConfOpen ? 'bg-primary' : ''} text-white w-full px-2 py-1 mb-1 flex items-start rounded gap-3`
                                                    }
                                                >
                                                    <p className="text-[14px] font-normal text-white">
                                                        Configuration
                                                    </p>
                                                </NavLink>
                                            </Disclosure.Panel>
                                        </Transition>
                                    </>
                                )}
                            </Disclosure>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        refreshData: state.HeaderReducer.refreshData,
        role: localStorage.role,
        hasPlan: state.domain?.data?.hasPlans,
        appUrl: state.domain?.appUrl,
        showReseller: state.HeaderReducer.showReseller,
    };
};

export default connect(mapStateToProps)(SettingTabSidebar);
