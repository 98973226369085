import { useEffect, useRef } from "react";
import { ToastContainer } from "react-toastify";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import Emitter from "../constants/Emitter";
import ScrollToTop from "react-scroll-to-top";
import Swal from "sweetalert2";

// Common components
import BrandHeader from "@components/Header/Brand";
import Header from "@components/Header/Landing";
import Footer from "@components/Footer";
import InternalServerScreen from "@components/InternalServer";
import BrandRoutes from "./Brand";
import Popup from "@components/Popup";

// Components not used in app
import BrandPlanPricingScreen from "@pages/brands/BrandPlanPricingScreen";
import GmailVerifyTokenScreen from "@pages/brands/GmailVerifyTokenScreen";
import BrandSettingWebhookScreen from "../unusedcomponents/BrandSettingWebhook";
import EamilVerificationScreen from "@pages/brands/EamilVerificationScreen";
import ShopifyVerifyTokenScreen from "../unusedcomponents/ShopifyVerifyToken";
import BrandInfluencerDeepScanScreen from "@pages/brands/BrandInfluencerDeepScanScreen";
import AuthShopifyCallbackScreen from "@pages/brands/AuthShopifyCallbackScreen";
import AnalyticsVerifyTokenScreen from "@pages/brands/AnalyticsVerifyTokenScreen";
import PricingLandingScreen from "@pages/landings/PricingLandingScreen";
import ContactScreen from "@pages/landings/ContactScreen";
import InfluencerFullProfileModal from "@components/BrandInfluencerDiscover/Profile/InfluencerFullProfileModal";
import SubHeader from "@components/Header/SubHeader";
import { useDispatch } from "react-redux";
import { fetchDomainInfo } from "@store/actions/DomainInfoAction";
import { useSelector } from "react-redux";
import CustomLoader from "@components/global/CustomLoader";
import { seo } from "@constants/helper";

const path = window.location.pathname;
const setToken = path.split("/");
const setInvite = path.split("/");
const setPlan = path.split("/");
const verifyToken = path.split("/");

if (path === "/appsumo/registration") {
  localStorage.removeItem("access-token");
  localStorage.removeItem("isLogin");
  localStorage.removeItem("role");
}

const AuthChecker = () => {
  if (path === "/brand/login") {
    if (localStorage.getItem("isLogin")) {
      window.location.href = "/dashboard";
    }
  }
  if (path === "/brand/register") {
    if (localStorage.getItem("isLogin")) {
      window.location.href = "/dashboard";
    }
  }
};

const UrlChecker = () => {
  if (path === "/login") {
    window.location.href = "/brand/login";
  }
  if (path === "/") {
    if (localStorage.role === "brand") {
    }
  }
};

const showHeaderOrFooter = () => {
  return (
    path === "/" ||
    path === "/brand" ||
    path === "/about-us" ||
    path === "/discovery" ||
    path === "/analyzer" ||
    path === "/influencer-marketing" ||
    path === "/discover-recipes" ||
    path === "/affiliate-program" ||
    path === "/managed-services" ||
    path === "/ugc" ||
    path === "/plant-tree" ||
    path === "/monitor-campaign-tools" ||
    path === "/social-monitoring-tools" ||
    path === "/insta-stories-monitoring" ||
    path === "/ecom-video-ads" ||
    path === "/sponsored-posts" ||
    path === "/pricing" ||
    path === "/plan/pricing" ||
    path === "/promotionalpricing" ||
    path === "/appsumo/registration" ||
    path === "/brand/get-started-free" ||
    path === "/brand/forgot-password" ||
    path === "/account-created" ||
    path === "/terms-of-service" ||
    path === "/privacy-policy" ||
    path === "/email/verify/" + verifyToken[3] ||
    path === "/gmail/verify" ||
    path === "/password/reset/" + setToken[3] ||
    path ===
      "/register/" + setInvite[2] + "/" + setInvite[3] + "/" + setInvite[4] ||
    path === "/500" ||
    path === "/403" ||
    path === "/404"
  );
};


const dynamicClassForHeader = () => {
  return path === "/" ||
    path === "/brand" ||
    path === "/about-us" ||
    path === "/discovery" ||
    path === "/analyzer" ||
    path === "/influencer-marketing" ||
    path === "/discover-recipes" ||
    path === "/affiliate-program" ||
    path === "/managed-services" ||
    path === "/ugc" ||
    path === "/plant-tree" ||
    path === "/social-monitoring-tools" ||
    path === "/monitor-campaign-tools" ||
    path === "/instagram-location" ||
    path === "/youtube-niche" ||
    path === "/youtube-location" ||
    path === "/tiktok-niche" ||
    path === "/tiktok-location" ||
    path === "/instagram-engagement-calculator" ||
    path === "/emv-calculator" ||
    path === "/tiktok-engagement-calculator" ||
    path === "/tiktok-email-finder" ||
    path === "/instagram-email-finder" ||
    path === "/youtube-email-finder" ||
    path === "/insta-stories-monitoring" ||
    path === "/ecom-video-ads" ||
    path === "/sponsored-posts" ||
    path === "/pricing" ||
    path === "/plan/pricing" ||
    path === "/promotionalpricing" ||
    path === "/brand/login" ||
    path === "/account-created" ||
    path === "/brand/register" ||
    path === "/appsumo/registration" ||
    path === "/brand/get-started-free" ||
    path === "/brand/forgot-password" ||
    path === "/terms-of-service" ||
    path === "/privacy-policy" ||
    path === "/email/verify/" + verifyToken[3] ||
    path === "/gmail/verify" ||
    path === "/password/reset/" + setToken[3] ||
    path === "/brand/register/" + setPlan[3] ||
    path ===
      "/register/" + setInvite[2] + "/" + setInvite[3] + "/" + setInvite[4] ||
    path === "/500" ||
    path === "/403" ||
    path === "/404"
    ? "w-full relative bg-bgBody overflow-x-hidden grow"
    : "w-full relative bg-bgBody overflow-x-hidden grow";
};

const CustomRoutes = () => {
  const popupRef = useRef(null);
  const drawerRef = useRef(null);
  const fullProfileRef = useRef(null);
  let token = localStorage.getItem("access-token");
  const location = useLocation();
  const dispatch = useDispatch();
  const domainInfo = useSelector((state) => state.domain?.data);
  const domainLoading = useSelector((state) => state.domain?.loading);
  const appUrl = useSelector((state) => state.domain?.appUrl);

  useEffect(() => {
    dispatch(fetchDomainInfo());
    ReactGA.initialize("G-7R5KBTDFKR");
    ReactGA.send("pageview");
    Emitter.on("PERMISSION_POPUP", firePermissionPopup);
    Emitter.on("MESSAGE_POPUP", messagePopup);
    Emitter.on("INFLUENCER_POPUP", influencerPopup);
    Emitter.on("FULL_PROFILE_POPUP", fullProfilePopup);
    return () => Emitter.off("PERMISSION_POPUP");
  }, []);

  useEffect(() => {
    const favicon = document.getElementById("favicon");
    if (domainLoading){
      favicon.href = "/favicon-logo.png"
    }

    if (domainInfo?.main_color) {
      const mainColor = domainInfo?.main_color;
      if (mainColor?.startsWith("#")) {
        document.documentElement.style.setProperty('--bgBody', mainColor || "#141625");
      } else {
        try {
          const colors = JSON.parse(mainColor);
          document.documentElement.style.setProperty('--primary', colors?.primary || "#603ed3");
          document.documentElement.style.setProperty('--bgBody', colors?.body || "#141625");
          document.documentElement.style.setProperty('--bgBodyDark', colors?.bodyDark || "#2d2f3c");
          document.documentElement.style.setProperty('--bgBodyLight', colors?.bodyLight || "#232639");
        } catch (error) {
          console.error("Invalid JSON format for main_color:", error);
        }
      }
    }
    
    if (favicon) {
      favicon.href = domainInfo?.favicon
    }
  
    // Google Tag Manager (GTM) script
    if (appUrl?.includes("app.btrendy.co")){
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l !== "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "GTM-N5M5ZKJ3");
  
      // GetReditus tracking script
      (function (w, d, s) {
        w.gr =
          w.gr ||
          function () {
            w.gr.q = w.gr.q || [];
            w.gr.q.push(arguments);
          };
        const p = d.getElementsByTagName(s)[0];
        const t = d.createElement(s);
        t.async = true;
        t.src = "https://app.getreditus.com/gr.js?_ce=60";
        p.parentNode.insertBefore(t, p);
      })(window, document, "script");
  
      // Track page view with GetReditus
      window.gr("track", "pageview");
    }
  }, [domainInfo, appUrl])

  useEffect(()=>{
    if(appUrl?.includes("alliedglobalmarketing")){
      seo({
        title: "Allied global marketing",
        metaDescription: "Elevate by Allied global marketing"
      })
    }else {
      seo({
        title: "Influencer marketing platform - Btrendy",
        metaDescription: "Reveal your own followers, Discover and filter through 34+ Million influencers. Analyze any influencer audience data and detect fake followers."
      })
    }
  },[location.pathname])



  const firePermissionPopup = () => {
    Swal.fire({
      title: "Permission denied",
      text: "Don’t have permission to do that action, contact your account admin",
      showCancelButton: false,
      showCloseButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "",
      confirmButtonColor: "#7c3292",
      cancelButtonColor: "#f4f4f5",
      customClass: {
        actions: "flex-row-reverse",
        closeButton: "hover:text-[#7c3292]",
        confirmButton: "hover:!shadow-none focus:!shadow-none min-w-[100px]",
        cancelButton:
          "hover:!shadow-none focus:!shadow-none min-w-[100px] !text-[#202020]",
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(() => {});
  };

  const messagePopup = (data) => {
    popupRef?.current?.open(data);
  };

  const influencerPopup = (data) => {
    drawerRef?.current?.open(data);
  };

  const fullProfilePopup = (data) => {
    fullProfileRef?.current?.open(data);
  };

  const navigate = useNavigate();

  const hideShowSideBar = () => {
    const pathParts = (location.pathname.includes('/list/') || location.pathname.includes('/report/')) && !!location?.state?.id;
    return pathParts
  }
  if (domainLoading){
    return (
      <div className="flex justify-center items-center h-screen">
        <CustomLoader />
      </div>
    )
  }
  return (
    <>
      <ToastContainer />
      {AuthChecker()}
      {UrlChecker()}
      <div className="overflow-hidden w-full">
        {showHeaderOrFooter() ? (
          <Header />
        ) : localStorage.getItem("role") === "brand" ? (
          <BrandHeader hideShowSideBar={hideShowSideBar} navigate={navigate} />
        ) : null}
        <div className={`${token ? ` ${showHeaderOrFooter() || hideShowSideBar() ? 'lg:w-full' : 'lg:w-[calc(100%-280px)]'} w-full gap-5 p-[1rem_2rem_0rem] min-h-screen float-right m-0 bg-bgBody transition-all duration-200 ease-in flex flex-col` : `${dynamicClassForHeader()}`}`}>
          <BrandRoutes />

          <Routes>
            <Route
              exact
              path="/auth/shopify/callback"
              element={<AuthShopifyCallbackScreen />}
            />
            <Route
              exact
              path="/brand/setting-brand-webhook"
              element={<BrandSettingWebhookScreen />}
            />
            <Route
              exact
              path="/plan/pricing"
              element={<BrandPlanPricingScreen />}
            />
            <Route
              exact
              path="/email/verify/:verifyToken"
              element={<EamilVerificationScreen />}
            />
            <Route
              path="/oauth/gmail/callback"
              element={<GmailVerifyTokenScreen />}
            />
            <Route
              path="/oauth/analytics/callback"
              element={<AnalyticsVerifyTokenScreen />}
            />
            <Route
              exact
              path="/shopify/verify"
              element={<ShopifyVerifyTokenScreen />}
            />
            <Route
              exact
              path="/brand/influencer/:platform/:id"
              element={<BrandInfluencerDeepScanScreen />}
            />
            <Route exact path="/pricing" element={<PricingLandingScreen />} />
            {/* <Route exact path="/contact" element={<ContactScreen />} /> */}
            <Route path="/500" element={<InternalServerScreen />} />
          </Routes>
        </div>
      </div>
      {/* {showHeaderOrFooter() ? <Footer /> : null} */}
      <ScrollToTop className="flex z-[99] items-center justify-center" smooth />
      <Popup ref={popupRef} onClose={() => {}} />
      <InfluencerFullProfileModal ref={fullProfileRef} onClose={() => {}} />
    </>
  );
};

export default CustomRoutes;
