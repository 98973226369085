import { Dialog, Transition } from "@headlessui/react";
import React, { forwardRef, useImperativeHandle } from "react";
import { IoMdClose } from "react-icons/io";
import colorPalette from "../../assets/data/colors.json" 
import { useDispatch } from "react-redux";
import { SET_RESELLER_INFO } from "@store/constants/action-types";

const ColorPalette = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = React.useState(false);

    useImperativeHandle(ref, () => ({
        open() {
            setIsOpen(true);
        },
        close() { },
    }));

    const onClose = () => {
        setIsOpen(false);
    };

    const handleSelectColor = (color) => {
        document.documentElement.style.setProperty('--primary', color.primary);
        document.documentElement.style.setProperty('--bgBody', color.body);
        document.documentElement.style.setProperty('--bgBodyDark', color.bodyDark);
        document.documentElement.style.setProperty('--bgBodyLight', color.bodyLight);
        dispatch({
            type: SET_RESELLER_INFO,
            payload: { "main_color": JSON.stringify(color) },
        });        
    }

    const handleSetDefault = ()=>{
        const defaultColor = {
            "primary": "#603ed3",
            "body": "#141625",
            "bodyDark": "#2d2f3c",
            "bodyLight": "#232639"
        }
        document.documentElement.style.setProperty('--primary', defaultColor.primary);
        document.documentElement.style.setProperty('--bgBody', defaultColor.body);
        document.documentElement.style.setProperty('--bgBodyDark', defaultColor.bodyDark);
        document.documentElement.style.setProperty('--bgBodyLight', defaultColor.bodyLight);
        dispatch({
            type: SET_RESELLER_INFO,
            payload: { "main_color": JSON.stringify(defaultColor) },
        });   
    }

    return (
        <Transition.Root show={isOpen} as={React.Fragment}>
            <Dialog as="div" className="relative z-50" onClose={onClose}>
                <Transition.Child
                    as={React.Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-[#000]/10 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-hidden">
                    <div className=" inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
                            <Transition.Child
                                as={React.Fragment}
                                enter="transform transition ease-in-out duration-300 sm:duration-300"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-300 sm:duration-300"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                                    <div className="w-full h-full bg-white">
                                        <div className="relative p-3 overflow-y-scroll">
                                            <div className="flex justify-between items-center">
                                                <p className="text-black text-lg">Choose Color Palette</p>
                                                <div
                                                    className="cursor-pointer"
                                                    onClick={onClose}
                                                >
                                                    <IoMdClose
                                                        size={24}
                                                        color="black"
                                                    />
                                                </div>
                                            </div>

                                            <div className="mt-10">
                                                <button onClick={handleSetDefault} className="bg-primary text-white rounded-lg w-full py-2">Set to default</button>
                                                {
                                                    colorPalette.map((color, index) => {
                                                        return (
                                                            <div onClick={() => handleSelectColor(color)} className="flex mt-5 cursor-pointer px-3 py-2 hover:bg-gray-50 rounded items-center gap-x-3" key={index}>
                                                                {
                                                                    Object.values(color).map((data) => (
                                                                        <div style={{ background: data }} key={data} className="w-10 h-10 rounded-full" />
                                                                    ))
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
});

export default ColorPalette;
