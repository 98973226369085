import Button from "@components/global/Button";
import React from "react";
import { useSelector } from "react-redux";
import { MdOutlineAddBox } from "react-icons/md";
import Emitter from "@constants/Emitter";

const Header = () => {
  const reports = useSelector((state) => state.BrandReports.reportDetail);

  return (
    <div className="containers p-8 flex justify-between items-center">
      <div>
        <div className="flex items-center mb-2 text-white text-[20px] font-medium">
          Reports
        </div>
        <p className="font-medium text-white">
          {reports.total || 0} Reports
        </p>
      </div>
      <Button
        onClick={() => Emitter.emit("CREATE_REPORT_POPUP")}
        className="px-6 justify-center rounded-[8px] h-[38px] text-[14px] inline-flex items-center bg-primary text-white hover:opacity-80"
        type="button"
        text="Create Report"
        prefix={<MdOutlineAddBox size={20} className="mr-3" />}
      />
    </div>
  );
};

export default Header;
