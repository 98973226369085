import { useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import {
  FaRegClone,
  FaUserFriends,
  FaRegClock,
  FaSpinner,
} from "react-icons/fa";
import { connect } from "react-redux";
import { FiChevronLeft } from "react-icons/fi";
import { HiPencil } from "react-icons/hi";
import Influencers from "./Influencers";
import AudienceOverlap from "./AudienceOverlap";
import moment from "moment";
import LinkTo from "@components/global/LinkTo";
import Button from "@components/global/Button";
import { toast } from "react-toastify";
import Boards from "./Boards";
import CustomLoader from "@components/global/CustomLoader";
import { Link } from "react-router-dom";
import { PiDotsNineBold } from "react-icons/pi";
import { MdFormatListBulleted } from "react-icons/md";

const formatedNumber = (num) => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "B";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
};

const UniqueAudiencePercentage = ({ totalFollowers, totalUniqueFollowers }) => {
  let uniqueAudience = ((totalUniqueFollowers / totalFollowers) * 100).toFixed(
    2
  );
  if (!isNaN(uniqueAudience)) {
    return uniqueAudience;
  } else {
    return 0;
  }
};

const OverlappingAudiencePercentage = ({
  totalFollowers,
  totalUniqueFollowers,
}) => {
  let overlappingAudience = (
    ((totalFollowers - totalUniqueFollowers) / totalFollowers) *
    100
  ).toFixed(2);
  if (!isNaN(overlappingAudience)) {
    return overlappingAudience;
  } else {
    return 0;
  }
};

const OverlappingAudienceFollwers = ({
  totalFollowers,
  totalUniqueFollowers,
}) => {
  let overlappingAudience = totalFollowers - totalUniqueFollowers;
  if (!isNaN(overlappingAudience)) {
    return overlappingAudience.toLocaleString();
  } else {
    return 0;
  }
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const ListView = (props) => {
  const {
    currentList,
    overlapping_loading,
    payload,
    refreshData,
    IsMainLoading,
    listBoards,
  } = props;
  const [editTitleFlag, setEditTitleFlag] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const loadMore = (page = 1) => {
    const { payload } = props;
    let query = {
      listId: props.id,
      platform: payload.platform,
    };
    props.getInfluencerList(page, query);
  };

  const handleChange = (event) => {
    if (event === 0) {
      setActiveTab(0);
      fetchAllDettail();
    }
    if (event === 1) {
      setActiveTab(1);
      props.addSelectedInfluencers([]);
      loadMore();
    }
    if (event === 2) {
      const { fetchAudienceOverlaps, currentList } = props;
      setActiveTab(2);

      fetchAudienceOverlaps(currentList.id);
    }
  };

  useEffect(() => {
    // fetchAllDettail();
    handleChange(0);
    return () => {
      props.addSelectedInfluencers([]);
    };
  }, [props.id]);

  const fetchAllDettail = async () => {
    const { payload } = props;
    let id = props.id;

    let query = {
      listId: id,
      platform: payload.platform,
    };

    await props.fetchListBoards(props.id);
    await props.viewList(query);
    loadMore();
  };

  if (IsMainLoading) {
    return (
      <div className="relative h-[87vh] w-screen flex justify-center items-center">
        <CustomLoader />
      </div>
    );
  }

  const showEditInput = () => {
    setEditTitleFlag(true);
  };

  const hideEditInput = () => {
    setEditTitleFlag(false);
  };

  const handleListNameEdit = (value) => {
    const { addForm } = props;
    const list = Object.assign({}, props.currentList);
    list.list_name = value;
    addForm(list);
  };

  const handleaddNewList = () => {
    const { currentList, saveList } = props;
    saveList(currentList);
    setEditTitleFlag(false);
  };

  const handleAudienceOverlap = async () => {

    const {
      currentList,
      audienceOverlap,
      influencersInList,
      payload,
      viewList,
      id,
      platform,
      selectedCards
    } = props;
    if (currentList.listInfluencersCount > 12 && (props?.selectedCards?.length < 2 || props?.selectedCards?.length > 12)) {
      toast.error("You must select influencers from range 2 to 12")
      return;
    }
    let query = {
      accounts: [],
      id: currentList.id,
      platform: payload.platform,
    };
    if (currentList.listInfluencersCount > 12 && props?.selectedCards?.length !== 0) {
      query.accounts = selectedCards && selectedCards.length ? selectedCards.map(card => card?.id ? card.id : card?.user_profile?.id) : [];

    }
    await audienceOverlap(query);

    let listQuery = {
      listId: id,
      platform: platform,
    };

    viewList(listQuery);
  };

  const removeFromList = async () => {
    const ids = props.selected_influencers.map((i) => i.user_profile.user_id);
    const data = { ids: ids, id: props.currentList.id };
    const json = await props.removeFromList(data);
    if (json.success) {
      loadMore();
    }
  };

  const starter = ["starter_month", "starter_year"];

  return (
    <>
      {props.influencerError &&
        toast.error("Some Influencers are not visible because of their emails")}
      <div className="bg-bgBodyDark">
        <div className="pt-12 pb-6 px-6">
          <div className="grid grid-cols-12 gap-5">
            <div className="md:col-span-8 col-span-12">
              <div className="p-1 mb-6 flex justify-between">
                <div className="h-[40px] flex items-center">
                  {editTitleFlag ? (
                    <div className="flex items-center">
                      <LinkTo
                        to={"/list/" + payload.platform}
                        className="text-[22px] mr-2 black bg-primary w-[30px] h-[30px] flex items-center justify-center rounded-full text-white"
                        prefix={
                          <FiChevronLeft size={20} className="text-white" />
                        }
                      />
                      <div className="mb-0">
                        <input
                          type="text"
                          name="listName"
                          className="rounded-[8px] h-[40px] inline-flex items-center px-3 border-[1px] border-[#ced4da] focus-visible:outline-0 focus:border-primary w-72"
                          value={currentList.list_name}
                          onChange={(e) => handleListNameEdit(e.target.value)}
                          placeholder="Enter List Name"
                        />
                        {props.errorsObj?.list_name ? (
                          <span className="red">
                            {this.props.errorsObj.list_name[0]}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="text-right ml-4">
                        <Button
                          className="px-3 rounded-[8px] h-[35px] text-[14px] inline-flex items-center bg-[#f4f4f5] text-[#000000de] hover:opacity-80"
                          onClick={() => hideEditInput()}
                          text="Cancel"
                        />
                        <Button
                          className="ml-4 px-3 rounded-[8px] h-[35px] text-[14px] inline-flex items-center bg-primary text-white hover:opacity-80"
                          onClick={() => handleaddNewList()}
                          text="Save"
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center">
                      <LinkTo
                        to={"/list/" + payload.platform}
                        className="text-[22px] mr-2 black bg-primary w-[30px] h-[30px] flex items-center justify-center rounded-full text-white"
                        prefix={<FiChevronLeft className="text-white" />}
                      />
                      <p className="text-[16px] text-white font-semibold mr-4">
                        {currentList.list_name}
                      </p>
                      {!refreshData.is_admin && editTitleFlag ? (
                        <HiPencil
                          size={20}
                          className="cursor-pointer"
                          onClick={() => showEditInput()}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </div>
              </div>
              <p className="p-1 flex text-white">
                <FaUserFriends size={18} className="mr-2 text-white" />{" "}
                <p className="pl-2 text-white">Influencers: </p>
                {currentList.listInfluencersCount}
              </p>
              <p className="p-1 flex text-white">
                <span className="font-semibold flex">
                  <FaRegClock className="mr-4 text-white" /> Total Audience:{" "}
                  {formatedNumber(currentList.total_followers)} followers
                </span>
              </p>
              {
                currentList?.listInfluencersCount > 0 && 
                <>                
                  {!starter.includes(refreshData.identifier) && (
                    <>
                      {!currentList.is_audience_overlap ? (
                        currentList.list_channel === "tiktok" ? (
                          <>
                            <p className="p-1 hidden text-white">
                              <FaRegClone className="mr-4 text-white" /> Audience overlap not
                              available in Tiktok
                            </p>
                          </>
                        ) : (
                          <>
                            <p className="p-1 hidden text-white">
                              <FaRegClone className="mr-4 text-white" /> Unique Audience: To
                              view audience overlapping report, please estimate
                              results.
                            </p>
                            <p className="p-1 hidden text-white">
                              <FaRegClone className="mr-4 text-white" /> Overlapping Audience:
                              To view audience overlapping report, please estimate
                              results.
                            </p>
                          </>
                        )
                      ) : (
                        <>
                          <p className="p-1 flex text-white">
                            <FaRegClone className="mr-4 text-white" /> Unique Audience:
                            <UniqueAudiencePercentage
                              totalFollowers={currentList.total_followers}
                              totalUniqueFollowers={
                                currentList.total_unique_followers
                              }
                            />
                            % |{" "}
                            {currentList.total_unique_followers
                              ? currentList.total_unique_followers.toLocaleString()
                              : 0}{" "}
                            followers
                          </p>
                          <p className="p-1 text-white hidden">
                            <FaRegClone className="mr-4 text-white" /> Overlapping Audience:
                            <OverlappingAudiencePercentage
                              totalFollowers={currentList.total_followers}
                              totalUniqueFollowers={
                                currentList.total_unique_followers
                              }
                            />
                            % |{" "}
                            <OverlappingAudienceFollwers
                              totalFollowers={currentList.total_followers}
                              totalUniqueFollowers={
                                currentList.total_unique_followers
                              }
                            />{" "}
                            followers
                          </p>
                        </>
                      )}
                    </>
                  )}
                  <p className="pl-9 text-white hidden">Request should contain 2 to 12 influencers.</p>
                </>
              }
            </div>
            <div className="md:col-span-4 gap-3 flex md:flex-nowrap flex-wrap justify-end col-span-12">
              <Link
                to={'/reports'}
                className="px-3 rounded-[8px] h-[40px] text-[14px] inline-flex items-center bg-primary text-white hover:opacity-80"
              >
                View Reporting
              </Link>
              {refreshData.is_admin &&
                !starter.includes(refreshData.identifier) && (
                  <div className="justify-end hidden">
                    {refreshData &&
                      refreshData.offer &&
                      !currentList.is_audience_overlap ? (
                      <div className="pl-2 text-white">
                        {currentList.list_channel !== "tiktok" ? (
                          <Button
                            onClick={() => handleAudienceOverlap()}
                            disabled={overlapping_loading || (currentList.listInfluencersCount > 12 && props?.selectedCards?.length === 0)}
                            className="px-3 rounded-[8px] h-[40px] text-[14px] inline-flex items-center bg-primary text-white hover:opacity-80"
                            text={
                              <>
                                {overlapping_loading ? (
                                  <FaSpinner
                                    size={22}
                                    className="animate-[spin_2s_linear_infinite]"
                                  />
                                ) : (
                                  ""
                                )}
                                Estimate Audience Overlap
                              </>
                            }
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      <div className="flex">
                        <Button
                          onClick={() => handleAudienceOverlap()}
                          disabled={overlapping_loading}
                          // disabled={overlapping_loading || (currentList.listInfluencersCount > 12 && (props?.selectedCards?.length < 2 || props?.selectedCards?.length > 12))}
                          className="px-3 rounded-[8px] h-[40px] text-[14px] inline-flex items-center bg-primary text-white hover:opacity-80"
                          text={
                            <>
                              {overlapping_loading ? (
                                <FaSpinner
                                  size={22}
                                  className="animate-[spin_2s_linear_infinite]"
                                />
                              ) : (
                                ""
                              )}
                              Update Overlapping Audience
                            </>
                          }
                        />
                      </div>
                    )}
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
      <Tab.Group
        defaultIndex={activeTab}
        onChange={(index) => {
          handleChange(index);
        }}
      >
        <div className="border-b-[3px] bg-bgBodyDark border-[#ccc] min-h-[50px] mb-[25px]">
          <Tab.List className="flex flex-row mb-0 sm:!mt-0">
            <Tab
              className={({ selected }) =>
                classNames(
                  "mr-[20px] min-w-[80px] text-center flex items-center gap-x-1 relative leading-[33px] text-white before:ease-[cubic-bezier(0.26,1.8,0.17,0.96)] bg-transparent text-[14px] py-[0.5rem] px-[1rem] before:content-[''] before:absolute before:bottom-[-3px] before:left-0 hover:before:w-full before:duration-[0.6s] before:delay-[0.1s] before:bg-primary before:h-[3px] focus-visible:outline-0",
                  selected
                    ? "font-semibold before:w-full"
                    : "font-normal before:w-0"
                )
              }
            >
              <PiDotsNineBold color="white" size={24} />
              Board View
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  "mr-[20px] min-w-[80px] text-center relative flex items-center gap-x-1 leading-[33px] text-white before:ease-[cubic-bezier(0.26,1.8,0.17,0.96)] bg-transparent text-[14px] py-[0.5rem] px-[1rem] before:content-[''] before:absolute before:bottom-[-3px] before:left-0 hover:before:w-full before:duration-[0.6s] before:delay-[0.1s] before:bg-primary before:h-[3px] focus-visible:outline-0",
                  selected
                    ? "font-semibold before:w-full"
                    : "font-normal before:w-0"
                )
              }
            >
              <MdFormatListBulleted color="white" size={24}  />
              List View
            </Tab>
            {currentList &&
              currentList.is_audience_overlap &&
              !starter.includes(refreshData.identifier) ? (
              <Tab
                className={({ selected }) =>
                  classNames(
                    "mr-[20px] min-w-[80px] text-center relative leading-[33px] text-white  before:ease-[cubic-bezier(0.26,1.8,0.17,0.96)] bg-transparent text-[14px] py-[0.5rem] px-[1rem] block before:content-[''] before:absolute before:bottom-[-3px] before:left-0 hover:before:w-full before:duration-[0.6s] before:delay-[0.1s] before:bg-primary before:h-[3px] focus-visible:outline-0",
                    selected
                      ? "font-semibold before:w-full"
                      : "font-normal before:w-0"
                  )
                }
              >
                Audience Overlap
              </Tab>
            ) : (
              ""
            )}
          </Tab.List>
        </div>
        <div className="overflow-x-auto overflow-y-hidden pb-[25px]">
          <Tab.Panels className="bg-transparent m-0 w-full max-w-full p-0">
            <Tab.Panel className={"focus-visible:outline-none"}>
              <Boards
                platform={currentList.list_channel}
                boards={listBoards}
              />
            </Tab.Panel>
            <Tab.Panel>
              <Influencers
                platform={currentList.list_channel}
                newCampaignWithSelected={true}
                loadMore={loadMore}
                removeFromList={removeFromList}
              />
            </Tab.Panel>
            <Tab.Panel>
              <div className="bg-[#d1ecf1] py-[0.75rem] px-[1.25rem] mb-[1rem] rounded-[8px] relative text-[#0c5460]">
                Overlapping Audience Report Date:{" "}
                {moment
                  .utc(currentList.updated_at)
                  .local()
                  .format("MMMM Do YYYY, h:mm:ss a")}
              </div>
              <AudienceOverlap platform={currentList.list_channel} />
            </Tab.Panel>
          </Tab.Panels>
        </div>
      </Tab.Group>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    errorsObj: state.brandList.errorsObj,
    currentList: state.brandList.current_list,
    listBoards: state.brandList.listBoards,
    influencersInList: state.brandList.influencerList,
    selectedCards: state.brandList.selectedCards,
    influencerError: state.brandList.influencerError,
    overlapping_loading: state.brandList.overlapping_loading,
    payload: state.brandList.payload,
    refreshData: state.HeaderReducer.refreshData,
    IsMainLoading: state.brandList.IsMainLoading,
    selected_influencers: state.campaign.selected_influencers,
  };
};

const mapDispatchToProps = (dispatch) => {
  const {
    actions: brandlistactions,
    types,
  } = require("@store/redux/BrandListRedux");

  const { actions: campaignactions } = require("@store/redux/CampaignRedux");

  return {
    addForm: (data) => {
      dispatch({ type: types.HANDLE_ADD_FORM, data: data });
    },
    saveList: (data) => {
      brandlistactions.saveList(dispatch, data);
    },
    viewList: (data) => {
      return brandlistactions.viewList(dispatch, data);
    },
    getInfluencerList: (page, data) => {
      brandlistactions.getInfluencerList(dispatch, page, data);
    },
    audienceOverlap: (data) => {
      return brandlistactions.audienceOverlap(dispatch, data);
    },
    fetchAudienceOverlaps: (data) => {
      brandlistactions.fetchAudienceOverlaps(dispatch, data);
    },
    removeFromList: (data) => {
      return brandlistactions.removeFromList(dispatch, data);
    },
    addSelectedInfluencers: (data) => {
      campaignactions.addSelectedInfluencers(dispatch, data, "list");
    },
    fetchListBoards: (data) => {
      return brandlistactions.fetchListBoards(dispatch, data);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListView);
